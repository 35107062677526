import React from 'react';
import { Badge } from 'domain/models/report.model';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import BadgeComponent from 'ui/components/commons/Badge';
import styles from './ResultsBadge.module.css';

type Props = {
  title: string;
  badge: Badge;
};

type ResultsBadgeProps = HTMLDivAttributesWithRef<Props>;

const ResultsBadge = React.forwardRef<HTMLDivElement, ResultsBadgeProps>(
  ({ title, badge, className, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      <div className={styles.title}>{title}</div>
      <BadgeComponent color={badge.color}>{badge.label}</BadgeComponent>
    </div>
  )
);

export default ResultsBadge;
