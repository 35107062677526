import React, { useMemo, useState } from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Slider, { useSliderContext } from 'ui/components/wrappers/Slider';
import Helper from 'ui/helpers/component.helper';
import Button, { ButtonProps } from './Button';
import styles from './ResultsSlider.module.css';
import Slide, { SlideProps } from './Slide';

type ResultsSliderProps = HTMLDivAttributesWithRef;

type ResultsSliderComponent = React.ForwardRefExoticComponent<ResultsSliderProps> & {
  Button: React.ForwardRefExoticComponent<ButtonProps>;
  Slide: React.ForwardRefExoticComponent<SlideProps>;
};

const ResultsSlider: ResultsSliderComponent = React.forwardRef<HTMLDivElement, ResultsSliderProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => {
    const [index, setIndex] = useState<number>(0);
    const [reversed, setReversed] = useState<boolean>(false);
    const { isTransitioning } = useSliderContext();

    const buttons = Helper.extractElements<ButtonProps>(children, Button);
    const slides = Helper.extractElements<SlideProps>(children, Slide);

    const changeIndex = (i: number) => {
      setReversed(i < index);
      setIndex(i);
    };

    const slide = useMemo(() => {
      if (!slides) return null;
      return <Slider reversed={reversed}>{slides && slides[index]}</Slider>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, reversed]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        <div className={styles.buttons}>
          {buttons &&
            buttons.map((button, key) =>
              Helper.cloneElement<ButtonProps>(button, {
                key,
                isSelected: index === key,
                isDisabled: isTransitioning,
                onClick: () => changeIndex(key),
                className: `${styles.button} ${button?.props.className}`,
              })
            )}
        </div>
        {slide}
      </div>
    );
  }
) as ResultsSliderComponent;

ResultsSlider.Button = Button;

ResultsSlider.Slide = Slide;

export default ResultsSlider;
