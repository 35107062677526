/* eslint-disable no-nested-ternary */
import { cloneDeep, isArray, isObject } from 'lodash';
import i18n from 'application/i18n';
import { BadgeColor, Report, SessionWithBadge, SessionWithWeights } from 'domain/models/report.model';
import { APIQuestionnaireWithWeight, APIResults, APISession, APISessionWithScore } from 'infra/models/results.model';
import { CheckUtil } from '../utils/check.util';

class ReportMapper {
  private static extractDates = (results: APIResults): (Date | null)[] => {
    const allSessions: APISession[][] = [
      results.depth['823'].sessions,
      results.agency['821'].sessions,
      results.support['823'].sessions,
      results.conflict['823'].sessions,
      results.avoiding['819'].sessions,
      results.selfEfficacy['820'].sessions,
      results.stateOfStress['818'].sessions,
      results.socialSupport['819'].sessions,
      results.problemFocused['819'].sessions,
      results.internalControl['822'].sessions,
      results.externalControl['822'].sessions,
    ];
    const orderedDates = allSessions.reduce((acc: (Date | null)[][], curr) => {
      curr.forEach((obj, i) => {
        acc[i] = acc[i] || [];
        acc[i].push(obj.date ? new Date(obj.date) : null);
      });
      return acc;
    }, []);
    return orderedDates.map((dates) =>
      dates.some(Boolean) ? new Date(Math.max(...(dates.filter(Boolean) as Date[]).map((d) => d.getTime()))) : null
    );
  };

  private static updateSessionsDates = (data: unknown, dates: (Date | null)[]): unknown => {
    const clone = cloneDeep(data);

    const updateDates = (item: unknown): unknown =>
      CheckUtil.hasSessions(item)
        ? {
            ...item,
            sessions: item.sessions.map((session, i: number) => ({ ...session, date: dates[i] })),
          }
        : item;

    if (isArray(clone)) {
      return clone.map((item) => ReportMapper.updateSessionsDates(item, dates));
    }

    if (isObject(clone)) {
      const updatedClone = updateDates(clone) as { [key: string]: unknown };
      for (const key in updatedClone) {
        if (updatedClone[key]) updatedClone[key] = ReportMapper.updateSessionsDates(updatedClone[key], dates);
      }

      return updatedClone;
    }

    return clone;
  };

  private static symptomsToWeightsSessions = (symptoms: APIQuestionnaireWithWeight[]): SessionWithWeights[] =>
    symptoms[0].sessions
      .map(({ date }) => (date ? new Date(date) : null))
      .map((date, i) => ({
        date,
        symptoms: symptoms.map((symptom) => ({ title: symptom.title, weight: symptom.sessions[i]?.weight })),
      }));

  private static scoresSessionsToBadgesSessions = (
    sessions: APISessionWithScore[],
    desc: boolean
  ): SessionWithBadge[] => {
    const { t } = i18n;
    return sessions.reduce((result: SessionWithBadge[], session, i) => {
      const prevSession = sessions[i - 1];
      const badge = { label: '', color: session.color || ('gray' as BadgeColor) };

      // Generate badge label
      if (session.score && prevSession?.score) {
        const diff = session.score - prevSession.score;
        switch (Math.sign(diff)) {
          case 0:
            badge.label = t('labels.neutral');
            break;
          case desc ? -1 : 1:
            badge.label = t('labels.progression');
            break;
          default:
            badge.label = t('labels.regression');
            break;
        }
      }

      return [...result, { date: session.date ? new Date(session.date) : null, badge }];
    }, []);
  };

  static resultsToReport = (results: APIResults): Report =>
    ReportMapper.updateSessionsDates(
      {
        copingStrategy: {
          sessions: ReportMapper.symptomsToWeightsSessions([
            results.avoiding['819'],
            results.socialSupport['819'],
            results.problemFocused['819'],
          ]),
        },
        abilityToAct: {
          items: [results.agency['821'], results.selfEfficacy['820']],
        },
        relations: {
          categories: [
            {
              title: i18n.t('titles.my-family'),
              items: [results.depth['823'], results.support['823'], results.conflict['823']].map((questionnaire) => ({
                title: questionnaire.title,
                sessions: ReportMapper.scoresSessionsToBadgesSessions(questionnaire.sessions, questionnaire.desc),
              })),
            },
            {
              title: i18n.t('titles.my-advisor'),
              items: [results.depth['824'], results.support['824'], results.conflict['824']].map((questionnaire) => ({
                title: questionnaire.title,
                sessions: ReportMapper.scoresSessionsToBadgesSessions(questionnaire.sessions, questionnaire.desc),
              })),
            },
          ],
        },
        mediators: {
          items: [results.stateOfStress['818'], results.internalControl['822'], results.externalControl['822']],
        },
        traits: {
          items: [results.reading['825'], results.optimism['826']],
        },
      },
      ReportMapper.extractDates(results)
    ) as Report;
}

export default ReportMapper;
