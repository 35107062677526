import React, { CSSProperties, useState } from 'react';
import { WeightsSession } from 'domain/models/weights.model';
import ButtonOption from 'ui/components/commons/ButtonOption';
import DateString from '../DateString';
import styles from './ResultsWeights.module.css';

type Props = {
  sessions: WeightsSession[];
};

const ResultsWeights: React.FC<Props> = ({ sessions }): JSX.Element | null => {
  const [current, setCurrent] = useState<number>(0);

  const currentSession: WeightsSession = sessions[current];

  if (!sessions.length) return null;

  return (
    <div className={styles.root}>
      <div className={styles.dates}>
        {sessions.map((session, i) => (
          <ButtonOption
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            isDisabled={!session.date}
            isSelected={current === i}
            onClick={() => setCurrent(i)}
          >
            <DateString date={session.date} />
          </ButtonOption>
        ))}
      </div>
      <div className={styles.symptoms}>
        {currentSession.symptoms.map((symptom, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={styles.symptom}>
            <div
              className={styles.bar}
              style={{ '--height': `${symptom.weight}%`, '--opacity': (symptom.weight || 0) / 100 } as CSSProperties}
            />
            <div className={styles.title}>{symptom.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsWeights;
