import React from 'react';
import ButtonOption, { ButtonOptionProps } from '../../ButtonOption';
import styles from './Button.module.css';

export type ButtonProps = ButtonOptionProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonOption {...props} ref={ref} className={`${styles.root} ${className}`}>
      {children}
    </ButtonOption>
  )
);

export default Button;
