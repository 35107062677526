import React from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import styles from './Slide.module.css';

export type SlideProps = HTMLDivAttributesWithRef;

const Slide = React.forwardRef<HTMLDivElement, SlideProps>(
  ({ className, children, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      {children}
    </div>
  )
);

export default Slide;
