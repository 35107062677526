import React from 'react';
import { SessionWithBadge } from 'domain/models/report.model';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import Badge from 'ui/components/commons/Badge';
import { BadgeVariant } from 'ui/components/commons/Badge/Badge';
import DateString from 'ui/components/commons/DateString';
import { Lock, Flag } from 'ui/components/commons/Icons';
import styles from './Sessions.module.css';

type Props = {
  variant?: BadgeVariant;
  sessions: SessionWithBadge[];
};

export type SessionsProps = HTMLDivAttributesWithRef<Props>;

const Sessions = React.forwardRef<HTMLDivElement, SessionsProps>(
  ({ variant, className, sessions, ...props }, ref): JSX.Element | null => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} ref={ref} className={`${styles.root} ${className}`}>
      {sessions.map((session, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={styles.session}
          data-current={session.badge && sessions[i + 1] && !sessions[i + 1]?.badge}
        >
          <div className={styles.date}>
            <DateString date={session.date} />
          </div>
          <Badge variant={variant} color={session.badge?.color || 'gray'} className={styles.badge}>
            {session.badge?.label || (!i ? <Flag className={styles.icon} /> : <Lock className={styles.icon} />)}
          </Badge>
        </div>
      ))}
    </div>
  )
);

export default Sessions;
