import React, { useRef } from 'react';
import { HTMLDivAttributesWithRef } from 'domain/models/shared.model';
import styles from './Slider.module.css';
import { useSliderContext } from './Slider.provider';

const DURATION = 800;

type Props = {
  reversed: boolean;
  children: React.ReactNode;
};

type SlideProps = HTMLDivAttributesWithRef<Props>;

const Slider = React.forwardRef<HTMLDivElement, SlideProps>(
  ({ reversed, children, className, ...props }, forwardedRef) => {
    // eslint-disable-next-line no-param-reassign

    const context = useSliderContext();
    const currRef = useRef<HTMLDivElement>(null);
    const prevRef = useRef<HTMLDivElement>(null);
    const ref = React.useRef<HTMLDivElement>(null);

    const [prev, setPrev] = React.useState<React.ReactNode>();
    const [curr, setCurr] = React.useState<React.ReactNode>(children);
    const [appeared, setAppeared] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    React.useImperativeHandle(forwardedRef, () => ref.current!);

    React.useEffect(() => {
      setCurr(children);
    }, [children]);

    React.useEffect(() => {
      const container = ref.current;
      const currElement = currRef.current;
      const prevElement = prevRef.current;

      if (!curr || !container || !currElement || !prevElement) return undefined;

      if (!appeared) {
        setPrev(curr);
        setCurr(undefined);
        setAppeared(true);
        return undefined;
      }

      context.transitionStart();

      const x = reversed ? -1 : 1;

      requestAnimationFrame(() => {
        prevElement.style.transform = 'translateX(0)';
        prevElement.style.position = 'relative';
        prevElement.style.zIndex = '0';

        currElement.style.transform = `translateX(${100 * x}%)`;
        currElement.style.position = 'absolute';
        currElement.style.zIndex = '10';
        currElement.style.top = '0';

        requestAnimationFrame(() => {
          prevElement.style.transform = `translateX(${-100 * x}%)`;
          prevElement.style.transitionDuration = `${DURATION}ms`;
          prevElement.style.transitionTimingFunction = 'ease';

          currElement.style.transitionDuration = `${DURATION}ms`;
          currElement.style.transitionTimingFunction = 'ease';
          currElement.style.transform = 'translateX(0)';
        });
      });

      const timeout = setTimeout(() => {
        setPrev(curr);
        setCurr(undefined);

        prevElement.style.transform = 'translateX(0)';
        prevElement.style.transitionDuration = '0ms';
        prevElement.style.position = 'relative';

        currElement.style.transform = `translateX(${100 * x}%)`;
        currElement.style.transitionDuration = '0ms';
        currElement.style.position = 'absolute';
        currElement.style.zIndex = '10';
        currElement.style.top = '0';

        context.transitionEnd();
        setAppeared(true);
      }, DURATION);

      return () => clearTimeout(timeout);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curr]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...props} ref={ref} className={`${styles.root} ${className}`}>
        <div key="curr" ref={currRef} className={styles.slide}>
          {curr}
        </div>
        <div key="prev" ref={prevRef} className={styles.slide}>
          {prev}
        </div>
      </div>
    );
  }
);

export default Slider;
